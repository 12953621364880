<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <h2 class="brand-text text-primary ml-1">
            ASI/Cortavo
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          Welcome to the Portal! 👋
        </b-card-title>
        <b-card-text class="mb-2">
          Please sign-in to your account and start the adventure
        </b-card-text>

        <!-- form --><div v-if="!$auth.loading">
          <!-- show login when not authenticated -->
          <b-button
            v-if="!$auth.isAuthenticated"
            @click="login"
          >Log in</b-button>
          <!-- show logout when authenticated -->
          <b-button
            v-if="$auth.isAuthenticated"
            @click="logout"
          >Log out
          </b-button></div>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import {
  BButton, BCard, BLink, BCardTitle, BCardText,
} from 'bootstrap-vue'
// import { getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {
  components: {
    // BSV
    BButton,
    BCard,
    BCardTitle,
    BLink,
    BCardText,
  },
  data() {
    return {
      userEmail: '',
      password: '',
      status: '',
      userData: {
        id: 1,
        fullName: '',
        username: '',
        avatar: '',
        email: '',
        role: 'admin',
        ability: [
          {
            action: 'manage',
            subject: 'all',
          },
        ],
        accessToken: '',
        refreshToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjE3NjkyNjk2LCJleHAiOjE2MTc2OTMyOTZ9.V4WQWNJWStNV0NSIsyy0I_cTjQULNx0NUmsJjO3x_xU',
      },
    }
  },
  methods: {
    // Log the user in
    login() {
      this.$auth.loginWithRedirect()
      localStorage.setItem('userData', JSON.stringify(this.userData))
      // this.$router.replace(getHomeRouteForLoggedInUser(this.userData.role))
      this.$ability.update(this.userData.ability)
    },
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
